/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import GjjhRadio from "../../../common/jzcs/GjjhRadio";
import { RxUtil } from "@/assets/util.js";
import { format, timesFnt } from "@/assets/app.js";
export default {
  name: "lcfqYxjh",
  components: {
    LCFQheader,
    GjjhRadio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "监控结果有效性计划",
      pageIdentity: "监控结果有效性计划",
      showSjrq: false,
      showListpage: false,
      minDate: new Date(),
      currentDate: new Date(),
      //userSelect
      single: false,
      single2: true,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      formKey: "",
      data: {
        zzz: "",
        sjbm: "",
        sjry: ""
      }
    };
  },
  created() {
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let newDate = new Date();
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        //this.data.jhsssj = format(new Date());
        //this.onDefaultData(res);
      });
    },

    async cgORxj() {
      this.initBySolInstId();
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No; //+"&instId="+instId
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);

          // let zzz = resData.zzz;
          // let zzz_name = resData.zzz_name;
          // let zzzObj = [{id:zzz,text:zzz_name}];
          // this.data.zzz = JSON.stringify(zzzObj);

          this.data.zzz = this.setSelectData(resData.zzz, resData.zzz_name);
          this.data.sjry = this.setSelectData(resData.sjry, resData.sjry_name);
          this.data.sjbm = this.setSelectData(resData.sjbm, resData.sjbm_name);
          this.data.jhbh = resData.jhbh;
          this.data.jhmc = resData.jhmc;
          this.data.jkxm = resData.jkxm;
          this.data.jkfs = resData.jkfs;
          this.data.bz = resData.bz;
          this.data.jhsssj = resData.jhsssj;
        });
        /***/
        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });
      } else {
        // this.initBySolInstId();
      }
    },
    setSelectData(strId, strName) {
      let zzzObj = [{
        id: strId,
        text: strName
      }];
      return JSON.stringify(zzzObj);
    },
    onDefaultData(res) {
      let zzz = res.data.formModels[0].jsonData.zzz;
      let zzz_name = res.data.formModels[0].jsonData.zzz_name;
      let zzzObj = [{
        id: zzz,
        text: zzz_name
      }];
      this.data.zzz = JSON.stringify(zzzObj);
      let sjry = res.data.formModels[0].jsonData.sjry;
      let sjry_name = res.data.formModels[0].jsonData.sjry_name;
      let sjryObj = [{
        id: sjry,
        text: sjry_name
      }];
      this.data.sjry = JSON.stringify(sjryObj);
      let sjbm = res.data.formModels[0].jsonData.sjbm;
      let sjbm_name = res.data.formModels[0].jsonData.sjbm_name;
      let sjbmObj = [{
        id: sjbm,
        text: sjbm_name
      }];
      this.data.sjbm = JSON.stringify(sjbmObj);
    },
    onConfirmSjrq(timestamp) {
      this.data.jhsssj = timesFnt(timestamp, 'T');
      this.showSjrq = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.jhbh = item.F_JHBH;
      this.data.jhmc = item.F_JHMC;
      this.showListpage = false;
    },
    datas() {
      let zzz = JSON.parse(this.data.zzz);
      let sjbm = JSON.parse(this.data.sjbm);
      let sjry = JSON.parse(this.data.sjry);
      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            jhbh: this.data.jhbh,
            jhmc: this.data.jhmc,
            jkxm: this.data.jkxm,
            jkfs: this.data.jkfs,
            zzz: zzz[0].id,
            zzz_name: zzz[0].text,
            sjbm: sjbm[0].id,
            sjbm_name: sjbm[0].text,
            jhsssj: this.data.jhsssj,
            sjry: sjry[0].id,
            sjry_name: sjry[0].text,
            bz: this.data.bz
          }
        }]
      };
      var jsonData = JSON.stringify(jsonDataObj);
      console.log(jsonData);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.jhbh) || this.iN(this.data.jhmc) || this.iN(this.data.jkxm) || this.iN(this.data.zzz) || this.iN(this.data.sjbm) || this.iN(this.data.sjry)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        console.log(res);
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败或信息未填完整！", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败或信息未填完整！", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};